.tabs {
  &-wrapper-area {
    width: 100%;
    border-top: 1px solid #ccc;
  }
  &-section {
    margin-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #ccc;

    .section-title {
      color: #4d4d4d !important;
      h2 {
        font-family: "Quicksand", sans-serif;
        font-weight: 300;
      }
    }
  }
}

.react-tabs__tab-list {
  display: flex;
  border-top: 1px solid #ccc;
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
}

.react-tabs__tab {
  border: none;
  bottom: 0;
  text-align: center;

  &:focus {
    border: none;
    box-shadow: none;
  }
  .row {
    text-align: center;
  }

  .tabImg {
    margin: 0 auto;
    margin-bottom: 10px;
  }
  p {
    color: #4d4d4d;
    margin: 0 auto;
    transition: all 0.3s linear;
    &:hover {
      color: $brandColor;
    }
  }
}

.react-tabs__tab--selected {
  border-radius: 0;
}

.tabTextContainer {
  margin-top: 40px;

  h3 {
    font-family: "Quicksand", sans-serif;
    color: #4d4d4d;
    padding-bottom: 15px;
    border-bottom: 2px solid $brandColor;
    margin-bottom: 50px;
  }

  p {
    font-size: 17px;
    font-weight: 100;
    text-align: justify;
  }

  img {
    border-radius: 15px;
  }
}
