.apmt_cont {
  margin: 40px 0px;
}

.apmt_width {
  width: 80%;
  margin: 0 auto;
}
.apmt_form_cont {
  padding: 25px 15px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 110%;
}

.form_button {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.form_button button {
  padding: 10px 30px;
  background-color: #2f8be6;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
}
.form_button button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
