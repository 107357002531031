@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600&family=Quicksand:wght@300;500;700&family=Alegreya+Sans+SC:wght@100;300;400;500&family=Exo+2:wght@100;200;300;400;500&family=Josefin+Sans:wght@100;200;300;400;500&family=Lato:wght@100;300;400;700&family=Open+Sans:wght@300;400;600&family=Roboto+Mono:wght@100;300;400;500&family=Roboto:wght@100;300;400;500&display=swap");

//== Typography Variable
$baseFont: "Exo 2", sans-serif;
$baseFontSize: 14px;
$headingFont: "Quicksand", sans-serif;
$mdi-icon: "Material Design Icons";
$fa-icon: "FontAwesome";

//== Site Typography Colors
$paragraph: #323232;
$headingColor: #323232;
$ashColor: #aaaaaa;
$borderColor: #eee;
$hvrColor: #182141;

//== Site Brand Color
$brandColor: #ef586f;
$brandColorDark: darken($brandColor, 15%);
$brandColorLight: lighten($brandColor, 15%);

//== Alternative Color
$white: #ffffff;
$offWhite: #f8f9fc;
$black: #000000;
$blackSoft: #222222;
$navy: #3b5998;
$pink: #ff69b4;
$violet: #9933cc;
$olive: #559137;
$green: #008000;
$red: #e74c3c;
$orange: #f5740a;
$yellow: #fbbf00;

//== Social Media Icons Color
$facebook: #3b5999;
$twitter: #1da1f2;
$pinterest: #cb2028;
$youtube: #cc332d;
$reddit: #ff4500;
$linkedin: #0077b5;

//== Responsive Media Query Variables
$xlmax-device: "only screen and (min-width: 1200px) and (max-width: 1599.98px)";
$xl-device: "only screen and (min-width: 1200px)";
$lg-device: "only screen and (min-width: 992px) and (max-width: 1199.98px)";
$md-device: "only screen and (min-width: 768px) and (max-width: 991.98px)";
$sm-device: "only screen and (max-width: 767.98px)";
$xs-device: "only screen and (max-width: 575.98px)";
$xxs-device: "only screen and (max-width: 479.98px)";
$twelve: "only screen and (max-width: 1200px)";
