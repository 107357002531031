.feelbump {
  &-section {
    margin-top: 50px;

    .section-title {
      color: #4d4d4d !important;
      h2 {
        font-family: "Quicksand", sans-serif;
        font-weight: 300;
      }
    }

    .blue {
      text-align: center;

      h3 {
        font-family: "Quicksand", sans-serif;
        font-weight: 300;
      }
      h5 {
        font-family: "Quicksand", sans-serif;
        font-weight: 300;
        margin-top: 20px;
      }

      .indication-line {
        width: 274px;
        height: 2px;
        background-color: #69dceb;
        text-align: center;
        margin: 0 auto;
        position: relative;

        &:before {
          content: "";
          background-color: #69dceb;
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          -moz-border-radius: 100%;
          -webkit-border-radius: 100%;
          position: absolute;
          left: 0px;
          top: -6px;
        }

        &:after {
          content: "";
          background-color: #69dceb;
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          -moz-border-radius: 100%;
          -webkit-border-radius: 100%;
          position: absolute;
          top: -6px;
          left: auto;
          right: 0px;
        }
      }
    }
    .pink {
      text-align: center;

      h3 {
        font-family: "Quicksand", sans-serif;
        font-weight: 300;
      }
      h5 {
        font-family: "Quicksand", sans-serif;
        font-weight: 300;
        margin-top: 20px;
      }

      .indication-line {
        width: 274px;
        height: 2px;
        background-color: #71cd9d;
        text-align: center;
        margin: 0 auto;
        position: relative;

        &:before {
          content: "";
          background-color: #71cd9d;
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          -moz-border-radius: 100%;
          -webkit-border-radius: 100%;
          position: absolute;
          left: 0px;
          top: -6px;
        }

        &:after {
          content: "";
          background-color: #71cd9d;
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          -moz-border-radius: 100%;
          -webkit-border-radius: 100%;
          position: absolute;
          top: -6px;
          left: auto;
          right: 0px;
        }
      }
    }
  }
}
