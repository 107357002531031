/*======================
10. Services Style CSS
========================*/

.service-area-top {
  padding: 40px 0 130px;
  position: relative;
  z-index: 1;

  @media #{$md-device} {
    padding: 90px 0 135px;
  }

  @media #{$sm-device} {
    padding: 60px 0 135px;
  }

  &:before {
    @include overlay($brandColor, 0.8);
    z-index: -1;
  }

  .section-title {
    h2 {
      font-family: "Quicksand", sans-serif;
      font-weight: 300;
    }

    h1 {
      font-family: "Quicksand", sans-serif;
      font-weight: 300;
      color: #fff;
      font-size: 2.25rem !important;
      margin-top: -5px;
      margin-bottom: 18px;
    }
  }
}

.service-content-area {
  margin-top: -170px;
  position: relative;
  z-index: 1;
}

.service-item {
  margin-top: 30px;
  transition: 0.4s;
  position: relative;
  text-align: center;
  overflow: hidden;

  .service-txt {
    background-color: $offWhite;
    padding: 25px 0;

    h5 {
      font-weight: 300;
      margin-bottom: 0;

      @media #{$sm-device} {
        font-size: 16px;
      }
    }
    h3 {
      font-weight: 300;
      margin-bottom: 0;
      font-size: 1.25rem;
      @media #{$sm-device} {
        font-size: 16px;
      }
    }
  }

  .service-content {
    background-color: rgba($brandColor, 0.95);
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    position: absolute;
    transition: 0.4s;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;

    .dlIcon {
      width: 75px;
      height: 75px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @media #{$sm-device} {
      padding: 15px;
    }

    img {
      width: 50px;
      height: 50px;
      margin-bottom: 15px;
    }

    h5 {
      color: $white;
      font-weight: 500;
      margin-bottom: 18px;

      a {
        color: $white;
      }
    }
  }

  &:hover {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26);

    .service-content {
      left: 0;
    }
  }
}

.non-opacity {
  &:after,
  &:before {
    display: none;
  }
}
.package-list-items-list {
  ul li {
    font-size: 16px;
    line-height: 2.5rem;
    margin: 0px;
    padding: 0px;
    margin-left: 10px;
  }
}
.package-list-items-title h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: normal;
}
.package-list-items-price p {
  font-size: 16px;
  color: $brandColor;
  font-weight: 600;
  padding: 10px;
}
.package-list-items-price p:hover {
  font-size: 18px;
  color: $brandColor;
  font-weight: 600;
  padding: 10px;
  transition: 1s;
}
.package-list-items-button button {
  background-color: $brandColor;
  padding: 10px;
  margin: 10px;
  color: white;
  width: 180px;
}
.package-list-items-button button:hover {
  font-size: 16px;
  font-weight: 500;
}
.package-list-items-price-list {
  text-decoration: line-through;
}

@media (max-width: 576px) {
  .package-list-items-price p {
    font-size: 14px;
  }
  .package-list-items-price p:hover {
    font-size: 17px;
  }
  .package-list-items-button button {
    background-color: $brandColor;
    padding: 10px;
    margin: 10px;
    color: white;
    width: 150px;
    font-size: 13px;
  }
  .package-list-items-button {
    margin-bottom: 10px;
  }
  .package-list-items-price-list {
    margin: 2px;
  }
  .package-list-items-price {
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}

.package-paragraphs p {
  font-size: 16px;
  line-height: 1.5;
  text-align: justify !important;
  margin-left: 10px;
}
.package-paragraphs-list ul li {
  font-size: 16px;
  line-height: 2rem;
  color: #ef586f;
  font-weight: 500;
}
.package-title {
  font-size: 27px;
  font-weight: 500;
}
.package-titles {
  font-size: 34px;
  font-weight: 500;
}
// .package-list-items-button a {
//   align-items: right;
//   justify-content: right;
//   float: right;
// }

.careers-page-title {
  color: #ef586f;

  font-size: 38px;
}
