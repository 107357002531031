.section {
  &-gallery {
    .img-container {
      margin-bottom: 30px;
      box-shadow: 0 30px 20px -35px #999;
    }
    .img-text {
      background-color: #eeeeee;
      padding: 15px;
      font-size: 20px;
      text-align: center;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    p {
      margin: 0 auto;
    }
    img {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }
}
.nb-text {
  padding: 20px;
  margin-top: 0 !important;
}

.cityName{
  padding: 20px;
  margin: 0 0 0 35px !important;
  color: $brandColor;
  
}