/*===========================
4.1 - Main Navigation CSS
=============================*/

// SITEMAP PAGE
.sitemapDiv {
  li {
    list-style-type: none;
  }

  .siteMapSubBtn {
    padding: 10px;
    border-radius: 0px;
    margin-bottom: 10px;
    margin-left: 30px;
    color: $brandColor;
    background-color: $white;
    border: 1px solid $brandColor;
    &:hover {
      color: $white;
      background-color: $brandColor;
      border: 1px solid $brandColor;
    }
  }

  .siteMapMainBtn {
    padding: 10px;
    border-radius: 0px;
    margin-bottom: 10px;
    background-color: $brandColor;
    border: 1px solid $brandColor;
    &:hover {
      color: $brandColor;
      background-color: $white;
      border: 1px solid $brandColor;
    }
  }
}

.main-menu {
  max-width: 100%;
  //  align-content: center;

  & > li {
    margin-right: 30px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    & > a {
      color: $white;
      display: block;
      line-height: 1;
      font-size: 0.8rem;
      letter-spacing: 0;
      text-transform: uppercase;
      position: relative;

      // @media #{$lg-device} {
      //   font-size: 40px;
      // }
    }

    // Sub Menu
    &.has-submenu {
      margin-right: 37px;
      padding-right: 10px;
      position: relative;

      // Menu Item Arrows
      &:after {
        color: $white;
        content: "\f107";
        font-size: 16px;
        line-height: 10px;
        font-family: $fa-icon;
        position: absolute;
        right: -10px;
        top: 0;
      }

      // Sub Menu
      .submenu-nav {
        background-color: $white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        border-bottom: 2px solid $brandColor;
        position: absolute;
        left: -25px;
        top: 100%;
        transform: translateY(50px);
        transition: 0.4s;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        min-width: 250px;
        margin-top: 40px;
        border-top: 3px solid $brandColor;
        z-index: 99;

        @media #{$lg-device} {
          min-width: 220px;
          margin-top: 38px;
        }

        &:before {
          content: "";
          position: absolute;
          height: 40px;
          width: 100%;
          left: 0;
          bottom: 100%;
        }

        & > li {
          & > a {
            color: $paragraph;
            display: block;
            font-size: 13px;
            letter-spacing: inherit;
            text-transform: uppercase;
            padding: 10px 20px;
          }

          &:hover {
            & > a {
              background-color: $brandColor;
              color: $white;
            }
          }
        }

        // Mega Menu
        &-mega {
          display: flex;
          margin-left: -300px;
          padding: 0;
          width: 1080px;

          @media #{$lg-device} {
            width: 960px;
            margin-left: -245px;
          }

          .mega-menu-item {
            border-right: 1px solid $borderColor;
            padding: 30px;
            flex-basis: 25%;

            &:last-child {
              border-right: 0;
            }

            & > a {
              display: none;
            }

            ul {
              li {
                margin-bottom: 10px;

                &:last-child {
                  margin-bottom: 0;
                }

                a {
                  color: $paragraph;
                  font-size: 15px;
                  line-height: 1;

                  &:hover {
                    color: $brandColor;
                  }
                }
              }
            }
          }
        }
      }

      // After Hover Sub Menu
      &:hover {
        & > .submenu-nav {
          transform: none;
          opacity: 1;
          visibility: visible;
          pointer-events: visible;
        }
      }
    }
  }
}

/* Responsive Mobile Menu */
.res-mobile-menu {
  background-color: $brandColor;
  display: block !important;
  padding: 20px 30px;
  color: #fff;
  font-weight: 500;

  .mobile-nav {
    li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      position: relative;
      list-style: none;
      padding-top: 15px;

      &:last-child {
        border-bottom: 0;
      }

      a {
        color: $white;
        font-size: 16px;
        padding: 15px 0;
        margin: 0;
        text-transform: capitalize;
        position: relative;

        &:hover {
          color: $white;
          background-color: transparent;
        }
      }

      img {
        display: none;
      }

      ul {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0;
        padding-left: 10px;

        li {
          &.mega-menu-item {
            & > a {
              font-weight: 600;
            }
          }

          a {
            font-size: 13px;
            color: rgba($white, 0.7);
          }
        }
      }
    }
  }
  .kc-partners {
    width: 105%;
    margin-left: -5%;
  }
  .kc-partners a {
    color: #fff;
    text-transform: capitalize;
  }
  .kc-part-fertility {
    background-color: #7e52a0;
    border-color: #7e52a0;
    color: #ffff;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .kc-part-premature {
    background-color: #01a8bd;
    border-color: #01a8bd;
    color: #ffff;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
  }
}

.extra-submenu {
  position: absolute;

  margin-left: 248px;
  top: -5%;
  width: 120%;
  background-color: $white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: none;
  z-index: 99;
  display: none;
  visibility: none;
}
.extra-submenuDisplay {
  position: relative;
}
.extra-submenu li {
  padding: 8px;
}
.extra-submenu li a {
  color: #323232;
}
.extra-submenu li a:hover {
  color: white !important;
}
.extra-submenu li:hover a {
  color: white !important;
}
.extra-submenu li:hover {
  color: white !important;
}
.extra-submenu li:hover {
  background-color: $brandColor;
}
.extra-submenuDisplay:hover .extra-submenu {
  display: block;
  visibility: visible;
  border-top: 2px solid $brandColor;
  border-bottom: 2px solid $brandColor;
}

@media #{$sm-device} {
  .extra-submenu {
    position: relative;
    margin-left: 0;
    top: 0;
    min-width: 0;
    display: block;
    visibility: visible;
    background-color: $brandColor;
  }
}
