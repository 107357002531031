.trichart {
  &-section {
    padding-top: 50px;

    .section-title {
      color: #4d4d4d !important;
      h2 {
        font-family: "Quicksand", sans-serif;
        font-weight: 300;
      }
    }
    .img-container {
      .row {
        .col-4 {
          margin: 0;
          padding: 0 !important;
        }
      }
    }
    .pop-container {
      margin-top: 30px;
      .col-weeks {
        text-align: center;
      }
      .btn-weeks {
        width: 50px;
        height: 50px;
        color: #fff;
        background-image: url("../../img/heart.png");
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}

.tooltip-inner {
  padding: 20px;
  color: $paragraph;
  background-color: #fff;
  border: 1px solid $brandColor;
  border-radius: 20px;
}
.bs-tooltip-top .arrow {
  &:before {
    border-top-color: $brandColor;
  }
}
