.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  z-index: -1;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.wrapper img {
  /* width: 60%; */
  height: 80%;
}

.wrapper svg {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #ffff;
  font-size: 30px;
}
