body{
  overflow-x: hidden;
  overflow-y: scroll;
}
#mySidenav a {
  position: fixed;
  right: -193px;
  transition: all 0.4s linear;
  padding: 10px 15px;
  width: 240px;
  text-decoration: none;
  font-size: 20px;
  color: white;
  border-radius: 5px;
  z-index: 999;
  border: 2px groove white;
}
#mySidenav a:hover {
  right: -15px;
  color: white;
}
#call {
  top: 240px;
  background-color: rgb(239, 88, 111);
}
#bookapp {
  top: 295px;
  background-color: rgb(239, 88, 111);
}
