/*====================
07. Slider Style CSS
=======================*/
.slider {
  &-item {
    background-size: cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    height: 350px;
    //  max-width: 100%;

    @media #{$md-device} {
      height: 350px;
    }

    @media #{$sm-device} {
      height: 350px;
    }
  }

  //  contentFirst - Mysore Inaguration Slider
  &-contentFirst {
    font-family: "Quicksand", sans-serif;
    font-weight: 100;
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    padding-left: 10px;
    margin-right: 10px;
    position: relative;
    bottom: 170px;
    background-color: #ddb2b7;
    opacity: 0.7;

    @media #{$md-device,$lg-device} {
      bottom: 65px;
      max-width: 575px;
    }

    @media #{$sm-device} {
      bottom: 30px;
      max-width: 350px;
    }

    h2 {
      font-family: "Quicksand", sans-serif !important;
      font-weight: 700 !important;
      font-size: 28px;
      color: black !important;
      font-family: $headingFont;
      font-weight: 400;
      letter-spacing: 1px;
      //  text-transform: lowercase;
      //  text-shadow: 2px 1px $brandColor;

      @media #{$md-device,$lg-device} {
        font-size: 45px;
      }

      @media #{$sm-device} {
        font-size: 30px;

        br {
          display: block;
        }
      }
    }

    p {
      font-family: "Quicksand", sans-serif !important;
      font-weight: 500 !important;
      color: black !important;
      //  text-transform: lowercase;
      //  text-shadow: 2px 1px $brandColor;

      @media #{$xl-device} {
        max-width: 575px;
        font-size: 20px;
        color: #ccc;
      }
    }

    .btn {
      margin-top: 30px;

      @media #{$sm-device} {
        margin-top: 20px;
      }
    }

    &--2 {
      h2 {
        @media #{$xl-device,$lg-device} {
          font-size: 55px;
        }
      }
    }

    &.light {
      color: $white;

      h2 {
        color: $white;
      }
    }
  }

  //  contentFirst ENDS

  //  bannerSecond STARTS HERE
  &-bannerSecond {
    font-family: "Quicksand", sans-serif;
    font-weight: 100;
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    padding-left: 10px;
    margin-right: 10px;
    position: relative;
    top: 140px;
    // background-color: #ddb2b7;
    // opacity: 0.7;

    @media #{$md-device,$lg-device} {
      top: 180px;
      right: 270px;
      max-width: 575px;
    }

    @media #{$sm-device} {
      top: 150px;
      right: 24px;
      max-width: 350px;
    }

    .iconTextOne {
      position: relative;
      bottom: 310px;
      left: 250px;

      @media #{$sm-device} {
        left: -25px;
        bottom: 307px;
        width: 115px;
      }

      @media #{$md-device,$lg-device} {
        left: 90px;
        bottom: 390px;
        width: 115px;
      }
    }

    .iconTextTwo {
      position: relative;
      bottom: 373px;
      left: 535px;

      @media #{$sm-device} {
        left: 123px;
        bottom: 355px;
        width: 115px;
      }

      @media #{$md-device,$lg-device} {
        left: 352px;
        bottom: 438px;
        width: 115px;
      }
    }

    h2 {
      font-family: "Quicksand", sans-serif !important;
      font-weight: 700 !important;
      font-size: 28px;
      color: #874333 !important;
      font-family: $headingFont;
      font-weight: 400;
      letter-spacing: 1px;
      //  text-transform: lowercase;
      //  text-shadow: 2px 1px $brandColor;

      @media #{$md-device,$lg-device} {
        font-size: 45px;
      }

      @media #{$sm-device} {
        font-size: 30px;

        br {
          display: block;
        }
      }
    }

    p {
      font-family: "Quicksand", sans-serif !important;
      font-weight: 500 !important;
      color: #874333 !important;
      //  text-transform: lowercase;
      //  text-shadow: 2px 1px $brandColor;

      @media #{$xl-device} {
        max-width: 575px;
        font-size: 20px;
        color: #ccc;
      }
    }

    .btn {
      margin-top: 30px;

      @media #{$sm-device} {
        margin-top: 20px;
      }
    }

    &--2 {
      h2 {
        @media #{$xl-device,$lg-device} {
          font-size: 55px;
        }
      }
    }

    &.light {
      color: $white;

      h2 {
        color: $white;
      }
    }
  }

  //  bannerSecond ENDS HERE

  //  bannerCustomWhiteBg STARTS HERE
  &-bannerCustomWhiteBg {
    font-family: "Quicksand", sans-serif;
    font-weight: 100;
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    padding-left: 10px;
    margin-right: 10px;
    position: relative;
    //  top: 0px;
    bottom: 50px;
    // background-color: #ddb2b7;
    // opacity: 0.7;

    @media #{$md-device,$lg-device} {
      top: 0px;
      right: 0px;
      max-width: 575px;
    }

    @media #{$sm-device} {
      top: 0px;
      left: 10px;
      max-width: 350px;
    }

    h2 {
      font-family: "Quicksand", sans-serif !important;
      font-weight: 700 !important;
      font-size: 28px;
      color: #fff !important;
      font-family: $headingFont;
      font-weight: 400;
      letter-spacing: 1px;
      //  text-transform: lowercase;
      //  text-shadow: 2px 1px $brandColor;

      @media #{$md-device,$lg-device} {
        font-size: 45px;
      }

      @media #{$sm-device} {
        font-size: 30px;

        br {
          display: block;
        }
      }
    }

    p {
      font-family: "Quicksand", sans-serif !important;
      font-weight: 500 !important;
      color: #fff !important;
      //  text-transform: lowercase;
      //  text-shadow: 2px 1px $brandColor;
      text-align: center;
      margin-right: 135px !important;

      @media #{$xl-device} {
        max-width: 575px;
        font-size: 20px;
        color: #ccc;
        margin-right: 135px !important;
      }

      @media #{$sm-device} {
        margin-right: 30px !important;
      }
    }

    .btn {
      margin-top: 30px;

      @media #{$sm-device} {
        margin-top: 20px;
      }
    }

    &--2 {
      h2 {
        @media #{$xl-device,$lg-device} {
          font-size: 55px;
        }
      }
    }

    &.light {
      color: $white;

      h2 {
        color: $white;
      }
    }
  }

  //  bannerCustomWhiteBg ENDS HERE

  //  bannerNewOne STARTS HERE
  &-bannerNewOne {
    font-family: "Quicksand", sans-serif;
    font-weight: 100;
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    padding-left: 10px;
    margin-right: 10px;
    position: relative;
    //  top: 140px;
    float: right;
    left: 40px;
    bottom: 55px;
    // background-color: #ddb2b7;
    // opacity: 0.7;

    @media #{$md-device,$lg-device} {
      bottom: 20px;
      left: 10px;
      max-width: 575px;
    }

    @media #{$sm-device} {
      bottom: 20px;
      left: 10px;
      max-width: 350px;
    }

    .nextText {
      position: relative;
      top: 210px;
      left: 90px;
      color: $brandColor !important;
      font-weight: 900 !important;
      font-size: 24px;
      line-height: normal !important;

      @media #{$sm-device} {
        top: 210px;
        left: 90px;
        width: 115px;
      }

      @media #{$md-device,$lg-device} {
        top: 210px;
        left: 90px;
        width: 115px;
      }
    }

    h2 {
      font-family: "Quicksand", sans-serif !important;
      font-weight: 800 !important;
      font-size: 32px;
      color: #111111 !important;
      font-family: $headingFont;
      font-weight: 400;
      letter-spacing: 2px;
      //  text-transform: lowercase;
      //  text-shadow: 2px 1px $brandColor;

      @media #{$md-device,$lg-device} {
        font-size: 45px;
      }

      @media #{$sm-device} {
        font-size: 30px;

        br {
          display: block;
        }
      }
    }

    p {
      font-family: "Quicksand", sans-serif !important;
      font-weight: 500 !important;
      color: #111111 !important;
      letter-spacing: 0px;
      line-height: 15px;
      //  text-transform: lowercase;
      //  text-shadow: 2px 1px $brandColor;

      @media #{$xl-device} {
        max-width: 575px;
        font-size: 20px;
        color: #ccc;
      }

      @media #{$md-device,$lg-device} {
        color: #874333 !important;
      }

      @media #{$sm-device} {
        color: #874333 !important;
      }
    }

    .btn {
      margin-top: 30px;

      @media #{$sm-device} {
        margin-top: 20px;
      }
    }

    &--2 {
      h2 {
        @media #{$xl-device,$lg-device} {
          font-size: 55px;
        }
      }
    }

    &.light {
      color: $white;

      h2 {
        color: $white;
      }
    }
  }

  //  bannerNewOne ENDS HERE

  //  bannerSecondOne STARTS HERE
  &-bannerSecondOne {
    font-family: "Quicksand", sans-serif;
    font-weight: 100;
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    padding-left: 10px;
    margin-right: 10px;
    //  position: relative;
    //  top: 140px;
    //  float:right;
    //  left: 40px;
    position: relative;
    bottom: 12%;
    left: 40%;
    //  top:-100px;
    //  top: 50px;
    // background-color: #ddb2b7;
    // opacity: 0.7;

    @media #{$md-device,$lg-device} {
      bottom: 20px;
      left: 10px;
      max-width: 575px;
    }

    @media #{$sm-device} {
      bottom: 0px;
      left: 10px;
      max-width: 350px;
    }

    h2.textcolor-title {
      font-size: 26px !important;
    }
    .textcolor-title-9 {
      color: #5f3a74 !important;
      font-weight: 800 !important;
      font-family: "Quicksand", sans-serif !important;
    }

    .textcolor-title-9 {
      @media #{$sm-device} {
        position: relative;
        bottom: 35px;
        left: 21px;
        color: #111111 !important;
      }
    }

    .nextText {
      position: relative;
      top: 210px;
      left: 90px;
      color: $brandColor !important;
      font-weight: 900 !important;
      font-size: 24px;
      line-height: normal !important;

      @media #{$sm-device} {
        top: 210px;
        left: 90px;
        width: 115px;
      }

      @media #{$md-device,$lg-device} {
        top: 210px;
        left: 90px;
        width: 115px;
      }
    }

    h2 {
      font-family: "Quicksand", sans-serif !important;
      font-weight: 800 !important;
      font-size: 32px;
      color: #111111 !important;
      font-family: $headingFont;
      font-weight: 400;
      letter-spacing: 2px;
      //  text-transform: lowercase;
      //  text-shadow: 2px 1px $brandColor;

      @media #{$md-device,$lg-device} {
        font-size: 45px;
      }

      // @media #{$sm-device} {
      //   position: relative;
      //   bottom: 25%;
      //   left: 40%;
      //   bottom: 20px;
      //   left: 10px;
      //   max-width: 350px;
      // }

      @media #{$sm-device} {
        position: relative;
        bottom: 10px;
        left: 70px;
        font-size: 30px;

        br {
          display: block;
        }
      }
    }

    .fertilityLogo {
      position: relative !important;
      bottom: 20px;
      right: 0px;
      max-width: 33% !important;

      @media #{$sm-device} {
        bottom: 33px;
        left: 10px;
        max-width: 100% !important;
      }
    }

    // .weareNowOpen{
    //   position: relative;
    //   top: 0px;
    //   //  left:90px;
    //   h2 {
    //     @media #{$xl-device,$lg-device} {
    //       font-size: 55px;
    //     }
    //   }
    // }

    p {
      font-family: "Quicksand", sans-serif !important;
      font-weight: 500 !important;
      color: #111111 !important;
      letter-spacing: 0px;
      align-content: center !important;
      //  line-height: 15px;
      //  text-transform: lowercase;
      //  text-shadow: 2px 1px $brandColor;

      @media #{$xl-device} {
        max-width: 575px;
        font-size: 20px;
        color: #ccc;
      }

      @media #{$md-device,$lg-device} {
        color: #111111 !important;
      }

      @media #{$sm-device} {
        position: relative;
        bottom: 35px;
        left: 79px;
        color: #111111 !important;
      }
    }

    .btn {
      margin-top: 30px;

      @media #{$sm-device} {
        margin-top: 20px;
      }
    }

    &--2 {
      h2 {
        @media #{$xl-device,$lg-device} {
          font-size: 55px;
        }
      }
    }

    &.light {
      color: $white;

      h2 {
        color: $white;
      }
    }
  }

  //  bannerSecondOne ENDS HERE

  &-content {
    font-family: "Quicksand", sans-serif;
    font-weight: 100;
    font-size: 16px;
    font-weight: 500;
    margin: auto;

    @media #{$md-device,$lg-device} {
      max-width: 575px;
    }

    @media #{$sm-device} {
      max-width: 350px;
    }

    h2 {
      font-family: "Quicksand", sans-serif !important;
      font-weight: 700 !important;
      font-size: 28px;
      color: white;
      font-family: $headingFont;
      font-weight: 400;
      letter-spacing: 1px;
      text-transform: lowercase;

      @media #{$md-device,$lg-device} {
        font-size: 45px;
      }

      @media #{$sm-device} {
        font-size: 30px;

        br {
          display: block;
        }
      }
    }

    p {
      font-family: "Quicksand", sans-serif !important;
      font-weight: 500 !important;
      text-transform: lowercase;
      @media #{$xl-device} {
        max-width: 575px;
        font-size: 20px;
        color: #ccc;
      }
    }

    .btn {
      margin-top: 30px;

      @media #{$sm-device} {
        margin-top: 20px;
      }
    }

    &--2 {
      h2 {
        @media #{$xl-device,$lg-device} {
          font-size: 55px;
        }
      }
    }

    &.light {
      color: $white;

      h2 {
        color: $white;
      }
    }
  }
}
.slider-item {
  object-fit: cover;
}
.slider-item-mobile {
  height: 100%;
  width: 100%;
}
.slider-item-mobile img {
  width: 100% !important;
}
.slider_item_mobile_item {
  width: 100%;
}
.slider-area {
  position: relative;

  .slick-arrow {
    color: $brandColor;
    position: absolute;
    top: 50%;
    left: 50px;
    transform: translateY(-50%);
    font-size: 70px;
    z-index: 9;

    @media #{$sm-device} {
      font-size: 40px;
      left: 30px;
    }

    &.slick-next {
      left: auto;
      right: 50px;

      @media #{$sm-device} {
        right: 30px;
      }
    }
  }

  .slick-dots {
    display: flex !important;
    position: absolute;
    margin-top: 0;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  &--2 {
    .slick-arrow {
      color: $white;
    }
  }
}

.textcolor {
  &-1 {
    color: #fff !important;
  }
  &-2 {
    color: #874333 !important;
  }
  &-3 {
    color: #111111 !important;
  }
}
.text-capitalize {
  text-transform: uppercase !important;
}
.first-capitals {
  text-transform: capitalize !important;
}
h2.textcolor-pink {
  color: $brandColor !important;
}
