.findDoctorDiv {
  background-color: #f8f9fc;
  margin-top: 10px;
  //padding: 10px 10px 10px 10px
  //  padding-left: 40px;
  padding-top: 40px;

  @media #{$sm-device, $md-device} {
    padding: 50px 0px 0px 10px;
    margin-top: 10px;
  }

  .findDocText {
    margin-left: 0px;
    text-align: center;
    color: #ffff;
  }

  .chooseSpecialitySelect {
    padding: 10px;
    height: 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-color: #ffffff;
    &:focus {
      border-color: #ffffff;
    }

    @media #{$sm-device, $md-device} {
      width: 100%;
    }
  }

  .chooseSpecialityListGroup {
    margin-top: -4px !important;
  }

  .chooseSpecialityListBox {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-color: #ffffff;
    &:focus {
      border-color: #ffffff;
    }
    //width: 100%;
  }

  .chooseSpecialityInput {
    margin-top: 4px !important;
    height: 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-color: #ffffff;
    &:focus {
      border-color: #ffffff;
    }
  }

  .bookAppointmentBtn {
    background-color: $brandColor;
    border-color: $brandColor;
    &:hover {
      background-color: #ffffff;
      color: $brandColor;
    }
    @media #{$sm-device, $md-device} {
      width: 100%;
      margin-top: 12px;
    }
  }
  button {
    margin-top: 15px;
  }
}

.pinkBackground {
  background-color: $brandColor;
}

.doctorAppointmentContainer {
  padding: 30px 0px;
  border: 1px solid red;
  background: url(../../../assets/img/doctors-bg.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
}
.doctorAppointmentContainer h2 {
  text-align: center;
  color: #ffff;
}
.doctorAppointmentContainerMysore {
  width: 80%;
  margin: 30px auto;
  text-align: center;
}
.doctorAppointmentContainerMysore a {
  font-size: 25px;
  color: rgb(239, 88, 111);
  font-weight: bold;
}
