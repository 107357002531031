.step_container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.step_container h3 {
  font-size: 30px;
  font-weight: 600;
}
.step_container p {
  font-size: 25px;
  font-weight: 400;
}
