.sch-day-wrap {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
}

.docwrap {
  .icon-box-item {
    border: 1px solid #fff;

    &:hover {
      border: 1px solid #ccc;
    }
  }
}

.deptListDiv {
  //  background-color: $brandColor;
  max-width: 100%;
}

.cardComp {
  width: fit-content;
  padding: 10px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.cardStyle {
  //  color: #ffffff !important;
  background-color: $brandColor;
  height: 100%;

  &:hover {
    cursor: pointer;
    border: 2px solid #ffffff;
    //box-shadow: 5px 10px #888888;
  }
}

.cardTitle {
  color: #ffffff !important;
}
