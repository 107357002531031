/*==========================================
22. Services Details Page Style CSS
===========================================*/

.service-details-thumb {
  .slick-dots {
    display: flex !important;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.service-img {
  border-radius: 20px;
  box-shadow: 0 35px 18px -25px #999 !important;
}

.videoTestList {
  margin-bottom: 20px;
  //  padding: 0 20px !important;
  padding: 0px !important;

  @media #{$sm-device} {
    padding: 0 15px !important;
  }

  // .videoDiv{
  //   //  padding: 20px;
  // }

  .videoTestTitle {
    font-size: 18px;
    color: #000;
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .iframeClass {
    // height: 210px;
    // width: 375px;
    //  padding: 10px;

    height: 100%;
    width: 100%;
    padding-left: 10px;

    @media #{$md-device} {
      height: 100%;
      width: 100%;
      padding: 0px !important;
    }

    @media #{$sm-device} {
      height: 100%;
      width: 100%;
      padding: 5px !important;
    }
  }

  // .react-player {
  //   // position: absolute;
  //   // top: 100px;
  //   // left: 100px;
  // }
}

.speciality_text {
  margin-top: 40px;
}

.service-list {
  // .serviceRow{
  //   //  margin-bottom: 10px;
  // }

  .serviceTitleDiv {
    padding: 0px 0px 20px 17px;
  }

  h3 {
    font-weight: 600;
    margin-bottom: 35px;
  }
}

.service-details-info {
  margin-top: 35px;
  // text-align: justify;

  @media #{$sm-device} {
    margin-top: 30px;
  }

  h2 {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 15px;
  }

  h3 {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 15px;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 20px;
  }

  h6 {
    font-weight: 600;
  }

  p {
    font-size: 16px;
  }

  ul {
    li {
      margin: 15px auto;
      margin-left: 20px;
      font-size: 16px;
      &:before {
        font-family: FontAwesome;
        content: "\f105";
        margin-right: 20px;
      }
    }
  }

  .service-feature {
    li {
      position: relative;
      padding-left: 40px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: "\f046";
        font-family: $fa-icon;
        font-size: 30px;
        line-height: 1;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }
  }
}

.how-we-works-content {
  .icon-box-item {
    align-items: flex-start;

    &:not(:first-child) {
      margin-top: 40px;
    }

    .icon-box {
      &__info {
        width: 100%;
        flex-basis: auto;
      }
    }
  }

  &.service-details-page {
    .icon-box-item {
      margin-top: 40px;
    }
  }
}

.pagination-wrap {
  border-top: 1px solid $borderColor;
  padding-top: 10px;
  margin-top: 55px;

  @media #{$sm-device} {
    margin-top: 40px;
  }

  .pagination {
    li {
      &.prev {
        margin-right: auto;
      }

      &.next {
        margin-left: auto;
      }

      a,
      button {
        color: $brandColor;
        font-weight: 600;
        margin: 0 10px;

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        &.current {
          position: relative;

          &:before {
            background-color: $brandColor;
            content: "";
            position: absolute;
            left: 0;
            bottom: 2px;
            height: 2px;
            width: 100%;
          }
        }
      }
    }
  }
}

// Services Details Sidebar
.sidebar {
  &-single {
    background-color: $offWhite;
    padding: 30px;
    margin-top: 40px;

    @media #{$md-device, $sm-device} {
      margin-top: 30px;
    }
  }

  &-title {
    font-size: 26px;
    margin-bottom: 23px;
    font-weight: 500;

    @media #{$xs-device} {
      font-size: 22px;
    }
  }
}

// Services List
.service-list {
  li {
    border-bottom: 1px solid $borderColor;
    padding: 13px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    a {
      color: $paragraph;
      font-weight: 600;
      vertical-align: middle;

      img,
      i {
        margin-right: 15px;
        vertical-align: middle;
        font-size: 25px;
        height: 25px;
        width: 25px;
      }

      &:hover {
        color: $headingColor;
        padding-left: 10px;
      }
    }
  }
}

.service-details-wrapper {
  .service-details-content {
    @media #{$xl-device} {
      padding-right: 70px;
    }
  }

  .sidebar-wrap {
    @media #{$xl-device} {
      margin-left: -30px;
    }
    .sidebar-single {
      border-radius: 20px;
      .sidebar-title {
        font-weight: 300;
      }
      .sidebar-body {
        .service-list {
          li {
            a {
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}

.section-facilities {
  img {
    border-radius: 20px;
  }
}

#kangaroo-fertility {
  .tel-no {
    font-size: 13px;
    background-color: $brandColor;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 700;
    //  padding: 10px 12px;
    // padding: 10px 25px;
    padding: 10px 18px 12px 15px;
    border: 2px solid $brandColor;
    border-radius: 20px;
    color: #fff;
    //   float: right;
    margin-right: 20px;
  }
  .tel-no.kangaroocare-fertility {
    background-color: #7e52a0;
    border-color: #7e52a0;
    &:hover {
      background-color: #fff;
      color: #7e52a0;
    }
  }
  font-size: 29px;
  margin-left: 11%;
}

.list-color-adder h6 {
  color: $brandColor !important;
  font-weight: 500;
}
.center-address {
  display: flex;
  padding-bottom: 10px;
}

.center-address-name {
  font-size: 20px;
  font-weight: bold;
  color: #ef586f !important;
  margin-right: 15px;
  flex: 1;
}
.center-address-desc {
  font-size: 16px;
  flex: 3;
}
@media (max-width: 600px) {
  .center-address {
    flex-direction: column;
  }
}

.center_contact_form input {
  width: 100%;
  padding: 10px 0px;
  border: 1px solid #cccc;
  border-radius: 10px 20px;
  padding-left: 15px;
}

.center_contact_form > div {
  margin-bottom: 15px;
}

.center_contact_form input[type="submit"] {
  background-color: #ef586f;
}

.center_contact_form textarea {
  width: 100%;
  border: 1px solid #cccc;
  height: 50px;

  padding: 10px 0px;
  border-radius: 10px 20px;
  padding-left: 15px;
}

.thankyou {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
  gap: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #ef586f !important;
}
.thankyou h1 {
  text-align: center;
}
.thankyou a {
  background: #ef586f;
  color: #fff;
  padding: 15px 40px;
  border-radius: 5px 10px;
  text-align: center;
}
