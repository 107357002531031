.registration_container {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 80%;
  height: fit-content;
  margin: auto;
  position: relative;
  inset: 0;
  background-color: #fff;
  padding: 30px 15px;
  border-radius: 10px;
}

.registration_width {
  width: 95%;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5%;
}

.registration_width > div {
  width: 100%;
  padding: 10px;
}

.registration_width div:first-child {
  margin-top: 0px;
}
.registration_close {
  padding-top: 20px !important;
  border-top: 2px solid #ccc !important;
  text-align: right;
}
.registration_close button {
  padding: 5px 20px;
  border: 2px solid rgb(129, 129, 129);
  font-size: 16px;
  border-radius: 5px;
  background: rgba(134, 0, 0, 0.62);
  color: white;
  font-weight: bold;
}

.registration_form {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}

.registration_form > div {
  width: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  margin-top: 20px;
}
.registration_form label {
  display: block;
  font-size: 16px;
  font-weight: 500;
}
.registration_form input {
  display: block;
  width: 60%;
}

.reg_form_date > div {
  width: 60%;
}
.reg_form_gender > div {
  width: 60%;
  display: flex;
  gap: 10%;
}
.reg_form_gender > div section {
  display: flex;
  gap: 10%;
}
.reg_form_button {
  text-align: center;
  margin: 20px 0px 10px;
}

.reg_form_button button {
  display: block;
  background: rgba(3, 133, 0, 0.7);
  margin-top: 20px;
  color: #FFFFFF;
  font-weight: bold;
  border: 2px solid rgb(103, 103, 103);
  padding: 5px 20px;
  width: fit-content;
  margin: 0 auto;
}
.message{
  color: red;
  text-align: center;
  font-size: 18px;
}
