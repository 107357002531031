/*========================
09. Icon Box Style CSS
=========================*/

.icon-box-item {
  display: flex;
  align-items: flex-start;
  border: 1px solid transparent;
  border-radius: 15px;
  padding: 20px;
  transition: all 0.3s linear;
  margin-bottom: 30px;

  @media #{$md-device} {
    display: block;
  }

  @media #{$sm-device} {
    margin-top: 40px;
  }

  &:hover {
    cursor: pointer;
    transform: translateY(-5px);
    border: 1px solid #cccccc;
    box-shadow: 0 15px 10px -15px #999;
  }

  .icon-box {
    &__icon {
      text-align: center;
      margin-right: 30px;
      flex-basis: 150px;
      max-width: 100%;

      img {
        border-radius: 50%;
        box-shadow: 0 0 15px #ccc;
        margin-bottom: 10px;

        @media #{$sm-device} {
          margin-bottom: 0px;
          border-radius: 20px;
        }
      }

      @media #{$md-device} {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &__info {
      flex-basis: calc(100% - 50px);
      width: calc(100% - 50px);

      h6 {
        font-weight: 400 !important;
        font-size: 13px;
        color: #858585;
      }

      @media #{$xl-device} {
        flex-basis: 80%;
        width: 80%;
      }

      @media #{$md-device} {
        flex-basis: 100%;
        width: 100%;
      }

      @media #{$sm-device} {
        margin-top: 20px;
        text-align: justify;
        font-size: 11px;
        flex-basis: 100%;
        width: 100%;
      }

      p {
        color: $paragraph;
      }

      h5 {
        font-weight: 500;
        line-height: 1;
        font-size: 17px;
        margin-bottom: 10px;
      }
      .docreadmore {
        //  float: right;
        max-width: 100% !important;
        margin-left: -30px !important;
        border: 2px solid $brandColor;
        padding: 5px 20px;
        border-radius: 50px;
        color: white;
        background-color: $brandColor;
      }
      .bio {
        color: $paragraph;
      }
    }
  }
}
