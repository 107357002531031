.step_form {
  width: 85%;
  display: flex;
  justify-content: space-around;
  margin: 30px auto;
  gap: 5%;
}

.step_form > div {
  width: 45%;
}

.step_form label {
  display: block;
  font-size: 17px;
  font-weight: 700;
}

.step_form select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
}

.date_time_container h3 {
  font-size: 17px;
  font-weight: 700;
}
/* //date time container  */
.date_time_container {
  margin: 30px 0px 20px;
}
.date_time_item {
  display: flex;
  justify-content: center;
  gap: 5%;
  border: 2px solid #ccc;
}
.date_time_item > section {
  width: 45%;
  padding: 30px;
}
.date_date_item {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* //time slots  */
.time_slot_item {
  width: 300px;
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  /*gap: 5%;*/
}
.time_slot {
  width: 30.8333%;
  padding: 3px 3px;
  color: #0a0a0a;
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 5px;
}
.time_slot_active {
  background-color: rgba(41, 162, 64, 0.83);
  color: #ffff;
}

.dark_btn{
  width: 30.8333%;
  background-color: #ef3e3e;
  color: #ffff;
  padding: 3px 3px;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 10px;
}


.danger{
  width: 30.8333%;
  padding: 3px 3px;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 10px;
  background-color: #ef3e3e;
  color: #ffff;
}

/*.holiday{*/
/*  width: 30.8333%;*/
/*  padding: 3px 3px;*/
/*  border-radius: 5px;*/
/*  font-weight: bold;*/
/*  margin-top: 10px;*/
/*  background-color: #2f2b2b;*/
/*  color: #ffff;*/
/*}*/


.slot_title{
  font-size: 18px;
  font-weight: bold;

}



/*.greyColor{*/
/*  background: grey;*/
/*  color: white;*/
/*  width: 15.8333%;*/
/*  cursor: none;*/
/*  padding: 10px 20px;*/
/*  border-radius: 5px;*/
/*  font-weight: bold;*/
/*  margin-top: 10px;*/
/*}*/
/*.greenColor{*/
/*  background-color: #40c057;*/
/*  color: white;*/
/*  cursor: none;*/
/*  padding: 10px 20px;*/
/*  border-radius: 5px;*/
/*  width: 15.8333%;*/
/*  font-weight: bold;*/
/*  margin-top: 10px;*/
/*}*/
/*.greenColor .time_slot_active{*/

/*}*/




/* Modal Content/Box */
.modal_content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.hide_block{
  display: none;
}
.show_message{
  display: block;
  font-size: 18px;
  color: red;
}

.hide_message{
  display: none;
}

.message{
  color: red;
  text-align: center;
  font-size: 18px;
}
.hide_slot_block{
  display: none;
}
.show_slot_block{
  display: block;
}
