/*==========================
3.0 - Template Spacing
============================*/

// Section Padding
.sp-top {
  padding-top: 110px;

  @media #{$md-device} {
    padding-top: 90px;
  }

  @media #{$sm-device} {
    padding-top: 60px;
  }
}

.sp-top-wt {
  padding-top: 105px;

  @media #{$md-device} {
    padding-top: 85px;
  }

  @media #{$sm-device} {
    padding-top: 57px;
  }
}

.sp-bottom {
  padding-bottom: 110px;

  @media #{$md-device} {
    padding-bottom: 90px;
  }

  @media #{$sm-device} {
    padding-bottom: 60px;
  }
}

.sp-bottom-wt {
  padding-bottom: 105px;

  @media #{$md-device} {
    padding-bottom: 85px;
  }

  @media #{$sm-device} {
    padding-bottom: 57px;
  }
}

.sp-y {
  padding: 110px 0;

  @media #{$md-device} {
    padding: 90px 0;
  }

  @media #{$sm-device} {
    padding: 60px 0;
  }
}

// Section Margin
.sm-top {
  margin-top: 110px;

  @media #{$md-device} {
    margin-top: 90px;
  }

  @media #{$sm-device} {
    margin-top: 60px;
  }
}
.sm-bottom {
  margin-bottom: 110px;

  @media #{$md-device} {
    margin-bottom: 90px;
  }

  @media #{$sm-device} {
    margin-bottom: 60px;
  }
}

.sm-top-wt {
  margin-top: 105px;

  @media #{$md-device} {
    margin-top: 85px;
  }

  @media #{$sm-device} {
    margin-top: 57px;
  }
}

.sm-y {
  margin: 110px 0;

  @media #{$md-device} {
    margin: 90px 0;
  }

  @media #{$sm-device} {
    margin: 60px 0;
  }
}

.ts {
  &10 {
    margin-top: 10px;
  }
  &20 {
    margin-top: 20px;
  }
  &25 {
    margin-top: 25px;
  }
  &30 {
    margin-top: 30px;
  }
  &40 {
    margin-top: 40px;
  }
  &50 {
    margin-top: 50px;
  }
  &60 {
    margin-top: 60px;
  }
  &75 {
    margin-top: 75px;
  }
  &100 {
    margin-top: 100px;
  }
  &125 {
    margin-top: 125px;
  }
  &150 {
    margin-top: 150px;
  }
  &175 {
    margin-top: 175px;
  }
  &200 {
    margin-top: 200px;
  }
}
.bs {
  &10 {
    margin-bottom: 10px;
  }
  &20 {
    margin-bottom: 20px;
  }
  &25 {
    margin-bottom: 25px;
  }
  &30 {
    margin-bottom: 30px;
  }
  &40 {
    margin-bottom: 40px;
  }
  &50 {
    margin-bottom: 50px;
  }
  &60 {
    margin-bottom: 60px;
  }
  &75 {
    margin-bottom: 75px;
  }
  &100 {
    margin-bottom: 100px;
  }
  &125 {
    margin-bottom: 125px;
  }
  &150 {
    margin-bottom: 150px;
  }
  &175 {
    margin-bottom: 175px;
  }
  &200 {
    margin-bottom: 200px;
  }
}

.tp {
  &10 {
    padding: 10px 0px;
  }
  &20 {
    padding: 20px 0px;
  }
  &25 {
    padding: 25px 0px;
  }
  &30 {
    padding: 30px 0px;
  }
  &40 {
    padding: 40px 0px;
  }
  &50 {
    padding: 50px 0px;
  }
  &60 {
    padding: 60px 0px;
  }
  &75 {
    padding: 75px 0px;
  }
  &100 {
    padding: 100px 0px;
  }
  &125 {
    padding: 125px 0px;
  }
  &150 {
    padding: 150px 0px;
  }
  &175 {
    padding: 175px 0px;
  }
  &200 {
    padding: 200px 0px;
  }
}
