/*=====================
14. Blog Style CSS
======================*/

.blog-item {
  background-color: $offWhite;
  transition: 0.4s;
  margin-top: 30px;

  .blog-content {
    padding: 50px 30px 45px;

    @media #{$sm-device} {
      padding: 30px 15px 25px;
    }

    h2 {
      font-weight: 600;
      margin-bottom: 15px;

      a {
        color: $headingColor;

        &:hover {
          color: $hvrColor;
        }
      }
    }

    .blog-meta {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      a {
        color: $paragraph;
        font-weight: 400;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        @media #{$xs-device} {
          font-size: 14px;
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 10px 60px 0 rgba(71, 74, 182, 0.16);
    transform: translateY(-10px);
  }
}
.blog_table table,
th,
td {
  border: 1px solid black;
  padding: 5px;
}

.blog_table tr {
  line-height: 2rem;
}
.blog_table th {
  text-align: center;
}
#blog-list-style-ul li {
  list-style: square !important;
  padding: 5px !important;
  margin-left: 10px !important;
  font-size: 12pt;
}
#blog-list-style-ul-inside li {
  list-style-type: circle !important;
  padding: 5px !important;
  margin-left: 10px !important;
}

#blog-list-style-ol li {
  list-style: square !important;
  padding: 5px !important;
}

h6.list-heading {
  font-weight: bold;
  font-size: 15px;
  margin: 10px 0;
}

#blog-para {
  font-size: 12pt;
  font-family: "Exo 2";
  color: #000000;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

#left-align {
  margin-left: -10px !important;
}
#blog-list-style-ol-parent {
  margin-left: -20px !important;
}
