/*==========================
04. Index Area Style CSS
===========================*/

.header-area {
  background-color: $brandColor;
  padding: 0;
  transition: 0.4s;
  border-bottom: 2px solid $brandColor;

  @media #{$sm-device} {
    position: relative;
    top: 0;
    left: 0;
    background-color: $brandColor;
    padding: 0;
    transition: 0.4s;
    // border-bottom: 2px solid $brandColor;
    border-bottom: none;
    width: 100%;
    z-index: 9998;
  }

  .header-top {
    padding: 10px 0;
    background-color: white;
  }

  .header-bottom {
    padding: 10px;
  }

  .logo-area {
    text-align: center;
    img {
      width: 300px;
      height: auto;
      margin: 0 auto;
    }
  }

  &.sticky {
    max-height: 72px;
    animation: slideInDown 0.5s ease-in-out;
    padding: 0px 0 15px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;

    .container {
      background-color: $brandColor;
    }

    @media #{$sm-device, $md-device} {
      padding: 0px 0;
    }

    .logo {
      &-dark {
        display: none;
      }
    }

    .main-menu {
      .submenu-nav {
        margin-top: 25px;

        &:before {
          height: 25px;
        }
      }
    }
  }

  &.transparent:not(.sticky) {
    background-color: transparent;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;

    .logo {
      &-light {
        display: none;
      }
    }

    .main-menu {
      & > li {
        &.has-submenu {
          &:after {
            color: $brandColor;
            font-weight: 600;
          }
        }

        & > a {
          color: $brandColor;
          font-weight: 600;
          @media #{$sm-device} {
            font-size: 0.5rem;
          }
        }
      }
    }

    .header-action {
      margin-top: 15px;
      a,
      button {
        color: $brandColor;
        font-weight: 600;
      }
    }
  }
}

// Index Action
.header-action {
  color: $white;
  font-size: 22px;

  .header-top-wrap {
    @media #{$sm-device} {
      margin-right: none;
    }
    .btn-menu {
      border: 2px solid $brandColor;
      border-radius: 20px;
      background-color: $brandColor;

      &:hover {
        background-color: #ffffff;
        color: $brandColor;
      }

      @media #{$sm-device} {
        position: absolute;
        right: 30px;
      }
    }
  }

  @media #{$lg-device, $sm-device} {
    font-size: 20px;
  }

  .emnumber {
    font-size: 18px;
    color: $brandColor;
    font-weight: 600;
    letter-spacing: 2px;

    i {
      margin-right: 15px;
    }

    @media #{$sm-device} {
      margin-right: 10px;
      display: block !important;
      font-size: 11px;
      letter-spacing: 0px;
      padding: 5px;

      i {
        margin-right: 5px;
      }
    }
  }

  .secondNumber {
    font-size: 15px;
    color: $brandColor;
    font-weight: 600;
    letter-spacing: 2px;

    p {
      margin-left: 24px;
      display: inline;
    }

    i {
      margin-right: 15px;
    }

    @media #{$sm-device} {
      margin-right: 10px;
      //  margin-top: 10px;
      display: block !important;
      font-size: 11px;
      letter-spacing: 0px;
      padding: 6px;

      i {
        margin-right: 5px;
      }

      p {
        margin-left: 11px;
      }
    }
  }

  .thirdNumber {
    font-size: 15px;
    color: $brandColor;
    font-weight: 600;
    letter-spacing: 2px;
    text-align: right !important;
    //  margin-right: 1px;

    i {
      margin-right: 15px;
    }

    @media #{$sm-device} {
      margin-right: 11px;
      //  margin-top: 10px;
      display: block !important;
      font-size: 11px;
      letter-spacing: 0px;
      padding: 6px;

      i {
        margin-right: 5px;
      }
    }
  }

  a {
    color: $white;

    &.tel-no {
      font-size: 13px;

      background-color: $brandColor;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 700;
      //  padding: 10px 12px;
      // padding: 10px 25px;
      padding: 9px 14px 9px 13px;
      border: 2px solid $brandColor;
      border-radius: 20px;
      color: #fff;
      float: right;
      margin-right: 20px;
      @media #{$twelve} {
        display: none;
      }

      &:hover {
        background-color: #fff;
        color: $brandColor;
      }
    }
    &.tel-no.kangaroocare-fertility {
      background-color: #7e52a0;
      border-color: #7e52a0;
      &:hover {
        background-color: #fff;
        color: #7e52a0;
      }
    }
    &.tel-no.kats {
      background-color: #7868af;
      border-color: #7868af;
      &:hover {
        background-color: #fff;
        color: #7868af;
      }
    }

    &.tel-no.prematureBabyFoundation {
      background-color: #ef586f;
      border-color: #ef586f;
      &:hover {
        background-color: #fff;
        color: #ef586f;
      }
    }
  }

  [class*="btn-"] {
    color: $white;
    margin-left: 20px;
  }
}

.nav {
  & > li {
    & > a {
      font-weight: 600;
      letter-spacing: 1px;
    }
  }
}
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}
.nabh-logo {
  height: 100px;
  width: 150px;
  margin-left: 1%;
}
@media (max-width: 776px) {
  .nabh-logo {
    display: none;
  }
}

@media (max-width: 650px) {
  .logo-area img {
    width: 350px;
    height: auto;
    margin: 0 auto;
    max-width: 150%;
  }
}
