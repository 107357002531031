/*===========================
--. Footer Style CSS
===========================*/
.footer-area,
.call-top-action-wrap {
  padding-top: 75px;
  background-color: $offWhite;
  padding-bottom: 80px;
}

.widget-item {
  margin-top: 38px;

  .widget-title {
    color: $brandColor;

    font-family: "Source Sans Pro", sans-serif;
    font-size: 22px;
    font-weight: 300;
    line-height: 1;
    margin-top: -1px;
    margin-bottom: 22px;
    padding-bottom: 10px;
    border-bottom: 2px solid $brandColor;

    @media #{$sm-device} {
      margin-bottom: 15px;
    }
  }

  address {
    font-family: "Source Sans Pro", sans-serif;
    line-height: 2;
    margin-bottom: 0;
    font-weight: 300;
  }

  .footerSecondNumber {
    margin-right: 10px;
  }
}

.widget-list {
  li {
    line-height: 2;
    font-family: "Source Sans Pro", sans-serif;

    a {
      color: $paragraph;
      font-weight: 300;
      transition: 0.2s;

      &:hover {
        color: $brandColor;
        padding-left: 5px;
      }
    }
  }
}

// About Widget
.about-widget {
  img {
    max-width: 300px;
    margin-bottom: 20px;
  }
}

.copyright-txt {
  margin-top: 25px;
  font-weight: 500;

  @media #{$sm-device} {
    margin-top: 15px;
  }
}
.navimg {
  height: 24px;
  margin-top: -2px;
}
.footer-text {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  padding: 5px 15px;
}
