.step2_main_container {
  width: 100%;
  margin: 30px auto;
  border: 2px solid #ccc;
  padding: 10px;
}
.step2_main_container p {
  margin-bottom: 5px;
}
.step_search_container {
  display: flex;
  gap: 4%;
  height: 60px;
}
.step_search_type select {
  font-size: 15px;
  height: 40px;
  padding: 10px;
}
.step_phone {
  display: flex;
}

.regster_button_block{
  width: 300px;
}



.step_phone span {
  background-color: #ccc;
  color: #000;
  font-weight: 500;
  display: block;
  height: 40px;
  padding: 10px;
  border-radius: 10px 0px 0px 10px;
}
.step_phone input {
  font-size: 15px;
  height: 40px;
  padding: 10px;
}
.step_btn_search button {
  background-color: #40c057;
  padding: 10px 30px;
  cursor: pointer;
}


.step_btn_Verify button{
  background-color: #40c057;
  padding: 5px 5px;
  width: 100px;
  cursor: pointer;
}
.step_btn_search i {
  color: #fff;
  border-radius: 10px;
}
.step_btn_register button {
  background-color: #f77e17;
  color: #fff;
  padding: 4px 4px;
  font-weight: 500;
  font-size: 14px;
}
.step_btn_register button i {
  padding-right: 10px;
}

/* // members  */
.members_table {
  margin: 30px auto;
  width: 80%;
}
.members_header {
  display: flex;
  border-bottom: 2px solid #ccc;
  gap: 3%;
}
.members_header p {
  width: 30%;
  font-size: 18px;
}
.members_values > div {
  display: flex;
  gap: 3%;
}
.members_values > div > div {
  width: 30%;
  margin-top: 10px;
  font-size: 15px;
}
.members_values button {
  border: 2px solid #975aff;
  padding: 5px 10px;
  border-radius: 5px;
}
.members_add {
  margin: 20px 0px 10px;
  padding: 5px 15px;
  background-color: #975aff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
}

.member_active button {
  background-color: #975aff;
  color: #fff;
  border-radius: 5px;
}

.message{
 font-size: 18px;
  color: red;
  text-align: center;
}
