.step_items {
  display: flex;
  gap: 5%;
  margin: 30px 0px;
}
.step_items > section {
  width: 45%;
}
.step_items h2 {
  font-size: 21px;
  font-weight: 500;
}
.step_appiontment h3,
.step_date h3 {
  font-size: 17px;
  font-weight: 500;
  margin: 15px 0px 10px;
}
.step_appiontment > div {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
}
.step_appiontment > div div {
  width: 46%;
  margin-top: 20px;
}
.step_appiontment p,
.step_date p {
  font-size: 17px;
  font-weight: 400;
  margin: 10px 0px 15px;
  color: rgb(119, 119, 119);
  border-bottom: 2px solid rgb(20, 20, 20);
}

.step_date_items {
  display: flex;
  flex-direction: column;
}
.step_date_items > div {
  width: 100%;
  margin-top: 20px;
}

.step_confirm_btn{
  background: rgb(37, 211, 102);
  color: black;
  font-weight: bold;
}


.step_date_message textarea {
  width: 100%;
  height: 100px;
  font-size: 17px;
  font-weight: 400;
  color: rgb(119, 119, 119);
}
.step_date_message {
  font-size: 17px;
  font-weight: 400;
  margin: 10px 0px 15px;
  color: rgb(119, 119, 119);
}
.step_date_items {
  font-size: 17px;
  font-weight: 400;
  margin: 10px 0px 15px;
  color: rgb(119, 119, 119);
}
.step_date_items input {
  width: 100%;
}
.confirm_btn {
  text-align: center;
  margin-bottom: 30px;
}

.message{
  color: red;
  text-align: center;
  font-size: 18px;
}
