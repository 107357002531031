/*==========================================
23. Team Member Details Page Style CSS
===========================================*/

.member-desc {
  h2 {
    @include font-size(32px);
    margin-bottom: 8px;
  }

  h5 {
    opacity: 0.8;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 25px;
  }

  p {
    font-weight: 300;
    color: #848484;
    text-align: justify;
  }
}

.docPic {
  border-radius: 20px;
  box-shadow: 0 17px 11px -14px #656565;
  min-width: 100% !important;
}

.bookAppointmentDiv {
  margin-top: 50px;

  .appointmentBadge {
    font-size: 16px;
    background-color: $brandColor;
  }

  .appointmentText {
    margin-top: 10px;
  }

  @media #{$sm-device} {
    text-align: center;
    .appointmentBadge {
      display: block;
      margin-bottom: 5px;
      //margin-left: 2px;
    }

    .appointmentText {
      margin-bottom: 30px;
    }

    .appointmentTextHypen {
      display: none;
    }
  }
}

.onlineConsuleDiv {
  margin-top: 30px;
}

.book-appointment {
  //  margin-top: 40px;
  background-color: #e94d69;
  border: 2px solid #e94d69;
  padding: 10px 40px;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;

  &:hover {
    border: 2px solid #e94d69;
    color: #e94d69;
    background-color: #fff;
  }
}

.viewAllDoctorBtn {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: #e94d69;
  border: 2px solid #e94d69;
  padding: 10px 40px;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  height: 50px;

  &:hover {
    border: 2px solid #e94d69;
    color: #e94d69;
    background-color: #fff;
  }
}

.member-social-icons {
  display: flex;

  a {
    color: $white;
    display: block;
    font-size: 18px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    width: 50px;

    &.facebook {
      background-color: $facebook;
    }

    &.twitter {
      background-color: $twitter;
    }

    &.linkedin {
      background-color: $linkedin;
    }

    &.reddit {
      background-color: $reddit;
    }

    &.pinterest {
      background-color: $pinterest;
    }
  }
}

.member-social-icons-footer {
  display: flex;
  margin-top: 20px !important;
  //  margin-left: 10px !important;

  a {
    color: $brandColor;
    display: block;
    font-size: 18px;
    //  text-align: center;
    line-height: 40px;
    height: 40px;
    width: 50px;

    &.facebook {
      background-color: $facebook;
    }

    &.twitter {
      background-color: $twitter;
    }

    &.linkedin {
      background-color: $linkedin;
    }

    &.reddit {
      background-color: $reddit;
    }

    &.pinterest {
      background-color: $pinterest;
    }
  }
}

.contact-info {
  p {
    position: relative;
    padding-left: 85px;

    strong {
      position: absolute;
      width: 75px;
      left: 0;
      top: 0;

      &:after {
        content: ":";
        right: 0;
        position: absolute;
        top: 0;
      }
    }
  }
}

.contact-info-con {
  p {
    font-size: 16px;
    font-weight: 400;
  }
  h4 {
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
    color: #ffffff;
  }
  address {
    font-size: 16px;
    font-weight: 400;
  }
}

.mem-achieve-item {
  margin-top: 50px;

  h4 {
    font-weight: 600;
  }

  .skill-bar-wrap,
  .history-content-wrap {
    @media #{$xl-device} {
      max-width: 75%;
    }
  }

  .history-content-wrap {
    .icon-box-item {
      @media #{$md-device} {
        display: flex;
      }

      .icon-box__icon {
        margin-right: 20px;
        margin-bottom: 0;
        flex-basis: 100px;
        width: 100px;
      }
    }
  }

  &.member-education {
    margin-bottom: 55px;

    @media #{$sm-device} {
      margin-bottom: 40px;
    }
  }
}

.doctor-member-title h4 {
  color: $brandColor !important;
}
.member-desc h4 {
  color: $brandColor !important;
}

.doctor-member-title li ul {
  padding-left: 10px;
}
