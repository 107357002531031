.bookAppointmentanchor{
    text-decoration: none;
    background-color: #ef586f;
    display: block;
    width: 350px;
    color: white;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-top: 15px;
    border-radius: 5px;
}